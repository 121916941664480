<template>
    <v-layout wrap>
        <v-flex xs12 class="d-flex justify-space-between mx-5">
            <span class="display-1 pb-5 font-weight-medium">{{
                $route.meta.name
            }}</span>
            <v-btn color="secondary" @click="back">Înapoi</v-btn>
        </v-flex>
        <v-flex xs12 class="mx-5">
            <v-card class="pa-5">
                <v-layout>
                    <v-flex>
                        <v-flex>
                            <v-text-field
                                @input="delete errors.name"
                                :error-messages="errors.name"
                                v-model="data.name"
                                label="Nume"
                            ></v-text-field>
                        </v-flex>
                        <v-flex>
                            <v-file-input
                                @change="delete errors.file"
                                :error-messages="errors.file"
                                v-model="data.file"
                                accept="file/*"
                                label="Atașament legislație"
                            ></v-file-input>
                        </v-flex>
                    </v-flex>
                </v-layout>
                <v-card-actions class="my-10">
                    <v-spacer></v-spacer>
                    <v-btn @click="saveAndBack" color="secondary" width="300px"
                        >Salvează</v-btn
                    >
                    <v-btn
                        v-if="!editMode"
                        @click="save"
                        color="secondary"
                        width="300px"
                        >Salvează și rămăi pe pagină</v-btn
                    >
                    <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
        </v-flex>
    </v-layout>
</template>

<script>
import displayErrors from "@/mixins/displayErrors";
import back from "@/mixins/back";
import editMode from "@/mixins/editMode";

export default {
    name: "AddLegislation",
    metaInfo: {
        title: "Legislație -"
    },
    mixins: [displayErrors, back, editMode],
    mounted() {
        const urlParams = new URLSearchParams(location.search);
        this.id = urlParams.get("id");
        if (this.editMode) {
            this.getData();
        }
    },
    data: () => ({
        resourceUri: "/legislations"
    })
};
</script>
